<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <v-row class="py-0">
          <v-col cols="8" md="6">
            <PageHeader :title="title" />
          </v-col>
          <v-col cols="4" md="6">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="to-right"
                  v-on="on"
                  icon
                  v-bind="attrs"
                  color="primary"
                  @click="goToLogConfigsGenerales()"
                  ><v-icon size="30px">{{ listIcon }}</v-icon>
                </v-btn>
              </template>
              <span>Acceder a log de configuraciones</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-card>
          <v-card-text class="py-0">
            <v-form
              v-model="isFormValid"
              ref="form"
              form="form"
              id="form"
              @submit.prevent="saveConfig()"
            >
              <v-row>
                <v-col cols="6" class="py-0">
                  <v-switch
                    v-model="incluirCodSoli"
                    label="Al controlar topes incluir los códigos de la solicitud en curso"
                  ></v-switch>
                </v-col>
                <v-col cols="4" align="left" class="py-0">
                  <v-tooltip right max-width="30%">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="pt-5"
                        v-on="on"
                        v-bind="attrs"
                        color="primary"
                      >
                        {{ infoIcon }}</v-icon
                      >
                    </template>
                    <span
                      >Si se activa se considera lo cargado en la solicitud en
                      curso antes de grabarla más el historial grabado en el
                      sistema. Si está desactiva el control de topes se realiza
                      contra el historial ya grabado en el sistema.
                    </span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" class="py-0">
                  <v-switch
                    class="my-0 py-0"
                    v-model="configTopePorGrupoFliar"
                    label="Permitir configurar topes por grupo familiar"
                  ></v-switch>
                </v-col>
                <v-col cols="4" align="left" class="py-0">
                  <v-tooltip right max-width="30%">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="pt-0"
                        v-on="on"
                        v-bind="attrs"
                        color="primary"
                      >
                        {{ infoIcon }}</v-icon
                      >
                    </template>
                    <span
                      >Si se activa, se podrá indicar en la configuración de
                      topes que se debe considerar el historial del grupo
                      familiar
                    </span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" class="py-0">
                  <v-switch
                    class="my-0 py-0"
                    v-model="configTopePorGroupORango"
                    label="Habilitar 'Controlar todos los códigos del rango'"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class=" mb-5 mr-4">
            <v-spacer></v-spacer>
            <v-btn
              type="submit"
              :disabled="!isFormValid"
              :loading="isLoadingBtn"
              form="form"
              color="primary"
            >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-10 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import PageHeader from "@/components/ui/PageHeader";
import GoBackBtn from "@/components/shared/GoBackBtn";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  name: "ParametrosTopesAuto",
  components: {
    GoBackBtn,
    PageHeader,
    Ayuda
  },
  data: () => ({
    incluirCodSoli: false,
    configTopePorGrupoFliar: false,
    configTopePorGroupORango: false,
    title: "Parámetros de topes en autorizaciones",
    rules: rules,
    routeToGo: "TopesGenerales",
    settingsIcon: enums.icons.SETTINGS,
    infoIcon: enums.icons.SNB_INFO,
    listIcon: enums.icons.UL,
    isFormValid: false,
    isLoadingBtn: false,
    optionCode: enums.webSiteOptions.PARAM_TOPES_AUTO,
    showExpand: false,
    showHelp: false,
    showIcon: true
  }),
  created() {
    this.loadParamTopesAuto();
  },
  methods: {
    ...mapActions({
      fetchParamTopeAuto: "prestaciones/fetchParamTopeAuto",
      saveParamTopeAuto: "prestaciones/saveParamTopeAuto",
      setAlert: "user/setAlert"
    }),
    goToLogConfigsGenerales() {
      this.$router.push({
        name: "LogConfiguracionesGenerales",
        params: { routeToGo: "ParametrosTopesAuto", tipoConfigId: 2 }
      });
    },
    async loadParamTopesAuto() {
      const resp = await this.fetchParamTopeAuto();
      this.incluirCodSoli = resp.incluirCodSoliCurso;
      this.configTopePorGrupoFliar = resp.configTopeGrupoFliar;
      this.configTopePorGroupORango = resp.configTopeGrupoRango;
    },
    async saveConfig() {
      this.isLoadingBtn = true;
      const data = {
        incluirCodSoliCurso: this.incluirCodSoli,
        configTopeGrupoFliar: this.configTopePorGrupoFliar,
        configTopeGrupoRango: this.configTopePorGroupORango
      };
      try {
        const res = await this.saveParamTopeAuto(data);
        if (res.status === 200) {
          this.isFormValid = true;
          this.setAlert({ type: "success", message: "Guardado con éxito" });
          this.isLoadingBtn = false;
          // this.closeModal();
        }
      } catch {
        this.isLoadingBtn = false;
      }
    },
    closeModal() {
      this.$emit("closeAndReloadConfig");
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style scoped>
::v-deep label {
  margin-bottom: 0;
}
</style>
