var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showExpand),expression:"!showExpand"}],staticClass:"pr-0",attrs:{"cols":"12","md":_vm.showHelp ? 8 : 11,"sm":12}},[_c('GoBackBtn',{attrs:{"routeToGo":_vm.routeToGo,"tabId":false}}),_c('v-row',{staticClass:"py-0"},[_c('v-col',{attrs:{"cols":"8","md":"6"}},[_c('PageHeader',{attrs:{"title":_vm.title}})],1),_c('v-col',{attrs:{"cols":"4","md":"6"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"to-right",attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.goToLogConfigsGenerales()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"30px"}},[_vm._v(_vm._s(_vm.listIcon))])],1)]}}])},[_c('span',[_vm._v("Acceder a log de configuraciones")])])],1)],1),_c('v-card',[_c('v-card-text',{staticClass:"py-0"},[_c('v-form',{ref:"form",attrs:{"form":"form","id":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.saveConfig()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-switch',{attrs:{"label":"Al controlar topes incluir los códigos de la solicitud en curso"},model:{value:(_vm.incluirCodSoli),callback:function ($$v) {_vm.incluirCodSoli=$$v},expression:"incluirCodSoli"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"4","align":"left"}},[_c('v-tooltip',{attrs:{"right":"","max-width":"30%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pt-5",attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.infoIcon))])]}}])},[_c('span',[_vm._v("Si se activa se considera lo cargado en la solicitud en curso antes de grabarla más el historial grabado en el sistema. Si está desactiva el control de topes se realiza contra el historial ya grabado en el sistema. ")])])],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-switch',{staticClass:"my-0 py-0",attrs:{"label":"Permitir configurar topes por grupo familiar"},model:{value:(_vm.configTopePorGrupoFliar),callback:function ($$v) {_vm.configTopePorGrupoFliar=$$v},expression:"configTopePorGrupoFliar"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"4","align":"left"}},[_c('v-tooltip',{attrs:{"right":"","max-width":"30%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pt-0",attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.infoIcon))])]}}])},[_c('span',[_vm._v("Si se activa, se podrá indicar en la configuración de topes que se debe considerar el historial del grupo familiar ")])])],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-switch',{staticClass:"my-0 py-0",attrs:{"label":"Habilitar 'Controlar todos los códigos del rango'"},model:{value:(_vm.configTopePorGroupORango),callback:function ($$v) {_vm.configTopePorGroupORango=$$v},expression:"configTopePorGroupORango"}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:" mb-5 mr-4"},[_c('v-spacer'),_c('v-btn',{attrs:{"type":"submit","disabled":!_vm.isFormValid,"loading":_vm.isLoadingBtn,"form":"form","color":"primary"}},[_vm._v(" Guardar ")])],1)],1)],1),(_vm.showIcon)?_c('v-col',{staticClass:"to-right pt-10 px-0",attrs:{"cols":"12","md":!_vm.showHelp ? 1 : _vm.showExpand ? 12 : 4}},[_c('Ayuda',{attrs:{"optionCode":_vm.optionCode,"pantallaTitle":_vm.title},on:{"toggleExpand":function($event){_vm.showExpand = !_vm.showExpand},"toggleHelp":_vm.toggleHelp,"toggleIcon":function($event){return _vm.toggleIcon()}}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }